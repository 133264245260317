/* eslint-disable operator-linebreak */
import React, { useState } from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import { useForm, ValidationError } from '@formspree/react'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'

const inscriptionsAreActive = false

const CabaretInfosInscription = () => {
  const [state, handleSubmit] = useForm('meqbnkvw')
  const [selectedOption, setSelectedOption] = useState('')

  const handleRadioChange = (event) => {
    const { value } = event.target
    setSelectedOption(value)
  }

  if (state.succeeded) {
    return (
      <CabaretLayout>
        <h1 className='!my-24 !text-center'>Inscription réussie!</h1>
        <div className='cabaret-infos text-center'>
            <form action="/cabaret"><button type='submit'>Retour</button></form>
        </div>
      </CabaretLayout>
    )
  }

  const MemberIdentificationFields = ({ memberNumber }) => {
    const nomMembre = `nomMembre${memberNumber}`
    const fonctionMembre = `fonctionMembre${memberNumber}`
    const ageMember = `ageMembre${memberNumber}`
    return (
        <div className='cabaret-inscription-form-group-member'>
        <div className='cabaret-inscription-form-group-member-field'>
            <label htmlFor={nomMembre}>Nom du membre {memberNumber}</label>
            <input id={nomMembre} type="text" name={nomMembre} />
        </div>
        <div className='cabaret-inscription-form-group-member-field'>
            <label htmlFor={fonctionMembre}>Fonction du membre {memberNumber}</label>
            <input id={fonctionMembre} type="text" name={fonctionMembre} />
        </div>
        <div className='cabaret-inscription-form-group-member-field'>
            <label htmlFor={ageMember}>Âge du membre {memberNumber}</label>
            <input id={ageMember} type="text" name={ageMember} />
        </div>
    </div>
    )
  }

  return (
    <CabaretLayout>
        <div className='cabaret-infos cabaret-inscription'>

            <CabaretInfosNavigation />

            <h1>Inscriptions</h1>

            <div className='cabaret-infos-inner'>

                <div className='cabaret-inscription-intro'>
                    { inscriptionsAreActive ?
                    <div>
                        <h3>Inscriptions du 6 au 26 novembre 2024.</h3>
                        <h3>Les champs marqués d’un astérisque * sont obligatoires.</h3>
                        <h3>Si vous remplissez ce formulaire, l'équipe du Cabaret Festif! considère que vous avez bien pris connaissance des règlements du concours. L'équipe se réserve tout de même le droit de vous demander de prouver votre éligibilité à certains points pendant la période de sélection.</h3>
                        <h3>La période d'inscription prend fin le 26 novembre 2023 à 23h. Toute inscription non complétée ne sera pas prise en considération.</h3>
                        <h3>Les projets soumis seront analysés par un comité de sélection formé de professionnel.les de l'industrie musicale québécoise. Les 12 projets choisis seront contactés dans la semaine du 18 décembre 2023 et l'annonce publique de la cohorte aura lieu le 9 janvier 2024.</h3>
                        <h3>Les soirées de qualifications se tiendront les 27 janvier, 17 février, 9 mars.</h3>
                        <h3>La grande finale aura lieu le 30 mars 2024.</h3>
                        <h3>Au plaisir de vous entendre!</h3>
                    </div>
                      :
                    <div>
                        <h3>La période d’inscriptions est terminée.</h3>
                    </div>
                    }

                </div>

                {inscriptionsAreActive ?
                <form onSubmit={handleSubmit} className='block'>

                    <h2>Personne contact</h2>

                    {/* PRÉNOM */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="prenom">Prénom <span style={{ color: 'red' }}>*</span></label>
                        <input id="prenom" type="text" name="prenom" required />
                        <ValidationError prefix="Prénom" field="prenom" errors={state.errors} />
                    </div>

                    {/* NOM */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="nom">Nom <span style={{ color: 'red' }}>*</span></label>
                        <input id="nom" type="text" name="nom" required />
                        <ValidationError prefix="Nom" field="nom" errors={state.errors} />
                    </div>

                    {/* TÉLÉPHONE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="telephone">Numéro de téléphone <span style={{ color: 'red' }}>*</span></label>
                        <input id="telephone" type="tel" name="telephone" required />
                        <ValidationError prefix="Numéro de téléphone" field="telephone" errors={state.errors} />
                    </div>

                    {/* EMAIL */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="email">Adresse courriel <span style={{ color: 'red' }}>*</span></label>
                        <input id="email" type="email" name="email" required />
                        <ValidationError prefix="Adresse courriel" field="email" errors={state.errors} />
                    </div>

                    {/* ROLE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="role">Rôle <span style={{ color: 'red' }}>*</span></label>
                        <input id="role" type="text" name="role" required />
                        <ValidationError prefix="Rôle" field="role" errors={state.errors} />
                    </div>

                    <h2>Identification du projet</h2>

                    {/* NOM DU GROUPE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="nomProjet">Nom du groupe ou du projet solo <span style={{ color: 'red' }}>*</span></label>
                        <input id="nomProjet" type="text" name="nomProjet" required />
                        <ValidationError prefix="Nom du groupe ou du projet solo" field="nomProjet" errors={state.errors} />
                    </div>

                    {/* INSCRIPTION POUR */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="inscriptionPour">Inscription pour <span style={{ color: 'red' }}>*</span></label>
                        <div>
                            <div className='flex items-center'>
                                <input
                                    type="radio"
                                    value="solo"
                                    checked={selectedOption === 'solo'}
                                    onChange={handleRadioChange}
                                    className='mr-4'
                                />
                                <p className='px-2'>Artiste solo</p>
                            </div>
                            <div className='flex items-center'>
                                <input
                                    type="radio"
                                    value="groupe"
                                    checked={selectedOption === 'groupe'}
                                    onChange={handleRadioChange}
                                />
                                <p className='px-2'>Groupe</p>
                            </div>
                        </div>
                    </div>

                    {selectedOption === 'groupe' && (
                        <div className="cabaret-inscription-form-group cabaret-inscription-form-group-members">
                            <MemberIdentificationFields memberNumber={1} />
                            <MemberIdentificationFields memberNumber={2} />
                            <MemberIdentificationFields memberNumber={3} />
                            <MemberIdentificationFields memberNumber={4} />
                            <MemberIdentificationFields memberNumber={5} />
                            <MemberIdentificationFields memberNumber={6} />
                        </div>
                    )}

                    <h2>Détails du projet</h2>

                    {/* LANGUE DE COMPOSITION */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="langue">Choix Multiple <span style={{ color: 'red' }}>*</span></label>
                        <select id="langue" name="langue" required>
                            <option value="">Langue des compositions</option>
                            <option value="francais">Français</option>
                            <option value="anglais">Anglais</option>
                            <option value="autochtone">Autochtone</option>
                            <option value="autres">Autres</option>
                        </select>
                        <ValidationError prefix="Choix Multiple" field="langue" errors={state.errors} />
                    </div>

                    {/* STYLE MUSICAL */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="styleMusical">Style musical <span style={{ color: 'red' }}>*</span></label>
                        <input id="styleMusical" type="text" name="styleMusical" required />
                        <ValidationError prefix="Style musical" field="styleMusical" errors={state.errors} />
                    </div>

                    {/* FORMULE ENVISAGÉE POUR LE CONCOURS */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="formule">Formule envisagée pour le concours <span style={{ color: 'red' }}>*</span></label>
                        <textarea id="formule" name="formule" rows="4" required></textarea>
                        <ValidationError prefix="Formule envisagée" field="formule" errors={state.errors} />
                    </div>

                    {/* VILLE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="ville">Ville de provenance <span style={{ color: 'red' }}>*</span></label>
                        <input id="ville" type="text" name="ville" required />
                        <ValidationError prefix="Ville de provenance" field="ville" errors={state.errors} />
                    </div>

                    {/* EXPÉRIENCE DE SCÈNE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="experience">Expérience de scène <span style={{ color: 'red' }}>*</span></label>
                        <textarea id="experience" name="experience" rows="4" required></textarea>
                        <ValidationError prefix="Expérience de scène" field="experience" errors={state.errors} />
                    </div>

                    {/* LIENS D'ÉCOUTE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="liens">Liens pour l'écoute du projet (1 video + 3 audio obligatoires) <span style={{ color: 'red' }}>*</span></label>
                        <textarea id="liens" name="liens" rows="4" required></textarea>
                        <ValidationError prefix="Liens pour l'écoute du projet" field="liens" errors={state.errors} />
                    </div>

                    {/* PHOTO DE PRESSE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="photo">Photo de presse récente <span style={{ color: 'red' }}>*</span></label>
                        <input id="photo" type="file" name="photo" accept=".png, .jpg, .jpeg, .gif" required/>
                        <ValidationError prefix="Photo de presse récente" field="photo" errors={state.errors} />
                    </div>

                    {/* POURQUOI S'INSCRIRE */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="pourquoi">Pourquoi vous inscrivez-vous au concours? <span style={{ color: 'red' }}>*</span></label>
                        <textarea id="pourquoi" name="pourquoi" rows="4" required></textarea>
                        <ValidationError prefix="Pourquoi vous inscrivez-vous au concours?" field="pourquoi" errors={state.errors} />
                    </div>

                    {/* AUTRES INFOS */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="autres">Autres informations pertinentes / questions / commentaires</label>
                        <textarea id="autres" name="autres" rows="4" cols="100"></textarea>
                        <ValidationError prefix="Autres informations pertinentes / questions / commentaires" field="autres" errors={state.errors} />
                    </div>

                    {/* AUTRES INFOS */}
                    <div className="cabaret-inscription-form-group">
                        <label htmlFor="disponibilite">Si vous êtes indisponibles pour une ou des soirées du concours (27 janvier, 17 février, 9 mars), il est très important de nous en faire part. La soirée à laquelle vous vous produirez (si vous êtes choisi.es) sera déterminée au hasard. Les candidat.es doivent obligatoirement être disponibles le 30 mars, au cas où le projet se rendrait en finale.</label>
                        <textarea id="disponibilite" name="disponibilite" rows="4" cols="100"></textarea>
                        <ValidationError prefix="Date de non-disponibilite" field="disponibilite" errors={state.errors} />
                    </div>

                    {/* SUBMIT BUTTON */}
                    <button type="submit" disabled={state.submitting}>Soumettre</button>
                </form>
                  : null }

            </div>

        </div>
    </CabaretLayout>
  )
}

export default CabaretInfosInscription
